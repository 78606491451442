























































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { omit } from 'lodash'
import { v4 as uuid } from 'uuid'

import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import ExerciseTaskQuestionWrapper from '@/components/forms/exercise/ExerciseTaskQuestionWrapper.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
import ExerciseMaterialsMixin from '@/mixins/manager/ExerciseMaterialsMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import { NameValueResource, PracticeQuestionRequest } from '@/store/types'
import { GET_DEFAULT_PRACTICE_QUESTION_FORM } from '@/components/forms/exercise/constants'

enum MenuPracticeActions {
  ADD = 'add',
  COPY = 'copy',
  DELETE = 'delete',
  DOWN = 'down',
  UP = 'up',
}

@Component({
  components: {
    AttachmentsWrapper,
    ExerciseTaskQuestionWrapper,
    Parameters,
    TextAreaInput,
    TextInput,
    UploadInput,
    ValidationProvider,
  },
})
export default class ExerciseTaskPracticeQuestionForm extends Mixins(ExerciseMaterialsMixin, NotifyMixin) {
  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ required: true })
  private form!: PracticeQuestionRequest

  @Prop({ required: true })
  private index!: number

  @Prop({ default: false })
  private isLast!: boolean

  @Prop({ default: false })
  private isSpendingAt!: boolean

  private get actions() {
    return [
      { icon: '$plus', iconColor: 'teal', name: 'Добавить новый', value: MenuPracticeActions.ADD },
      { disabled: this.index === 0, icon: '$moveUp', iconColor: 'teal', name: 'Переместить вверх', value: MenuPracticeActions.UP },
      { disabled: this.isLast, icon: '$moveDown', iconColor: 'teal', name: 'Переместить вниз', value: MenuPracticeActions.DOWN },
      { icon: '$duplicate', iconColor: 'teal', name: 'Копировать', value: MenuPracticeActions.COPY },
      { icon: '$trash', iconColor: 'teal', name: 'Удалить вопрос', value: MenuPracticeActions.DELETE },
    ]
  }

  private get uploadParams () {
    return [{
      name: 'folder',
      value: `${this.subject.name}/Домашняя работа/Материалы`,
    }]
  }

  private handleAddForm() {
    this.$emit('add-form', this.index + 1, GET_DEFAULT_PRACTICE_QUESTION_FORM())
  }

  private handleCopyForm() {
    this.$emit('add-form', this.index + 1, { ...omit(this.form, 'id'), uuid: uuid() })
  }

  private handleMenuItemActionClick(action: MenuPracticeActions) {
    switch(action) {
    case MenuPracticeActions.ADD:
      this.handleAddForm()
      break
    case MenuPracticeActions.COPY:
      this.handleCopyForm()
      break
    case MenuPracticeActions.DELETE:
      this.$emit('delete-form', this.index)
      break
    case MenuPracticeActions.DOWN:
      this.$emit('moveDown', this.index)
      break
    case MenuPracticeActions.UP:
      this.$emit('moveUp', this.index)
      break
    }
  }
}
